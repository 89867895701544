import Script from 'next/script';

export const GoogleAnalyticsAsyncScript = (): JSX.Element | null => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID === undefined || process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID === '') {
    // Script is not loaded
    return null;
  }
  // Load google analytics script!
  return (
    // {@link https://nextjs.org/docs/messages/next-script-for-ga#using-gtagjs}
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
        `}
      </Script>
    </>
  );
};
