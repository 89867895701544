import { atom } from 'recoil';

export type AppState = {
  chatKey: string;
  apiKey: string;
  scenarioId: string;
  isChatOpen: boolean;
  siteOrigin: string;
};

export const appState = atom({
  key: 'appState',
  default: { isChatOpen: false } as AppState,
});
