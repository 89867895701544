export type LegacyAuthResponseError = {
  message: string;
  path: string[];
  extensions: {
    code: string;
    status: number;
  };
};

type TSendEmailParams = {
  apiKey: string;
  projectId: number;
  channelId: string;
  chatbotId: string;
};

export type ResponseBody = {
  data: {
    chatLogMailSender: boolean;
  };
  errors?: LegacyAuthResponseError[];
};

export async function send(params: TSendEmailParams, token: string) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_ADD_BACKEND || 'http://localhost:8080'}/customer/anonymous/chatbot/send_email`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(params),
    },
  );
  if (!response.ok) {
    throw new Error('メール送信に失敗しました');
  }

  const { data, errors } = (await response.json()) as ResponseBody;

  if (errors && errors.length > 0) {
    // TODO: 適切なエラーメッセージ出力
    //   暫定的に一つ目のエラーメッセージを表示している
    throw new Error(errors[0].message);
  }

  return data;
}
