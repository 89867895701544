import { atom } from 'recoil';
import { Identity } from '../../service/signIn/signIn.service';

export type TChatbotIdentity = {
  identity: Identity;
  channelId: string;
};

const initialChatbotIdentity: TChatbotIdentity = {
  identity: {
    firebaseToken: '',
    firebaseUuid: '',
    firebaseRefreshToken: '',
    firebaseTokenExpireAt: '',
    firebaseEmail: null,
    expireAt: '',
    projectId: -1,
    roomKey: '',
    roomName: '',
  },
  channelId: '',
};

export const chatbotIdentityState = atom({
  key: 'chatbotIdentityState',
  default: initialChatbotIdentity,
});
