import { addDoc, collection, doc, getFirestore } from 'firebase/firestore';
import { firebaseApp } from './app';

export const firestore = getFirestore(firebaseApp);

let rank = 0;

export const createChannel = async (projectId: string, customerId: string, scenarioId: string, chatbotId: string) => {
  const projectDocRef = doc(collection(doc(collection(firestore, 'projects'), projectId), 'chatbots'), chatbotId);
  const result = await addDoc(collection(projectDocRef, 'channels'), {
    created_at: new Date(),
    uid: customerId,
    scenario_id: scenarioId,
  });
  return result.id;
};

export const addMessageLog = async (projectId: string, channelId: string, text: string, chatbotId: string) => {
  const channelDocRef = doc(
    collection(doc(collection(doc(collection(firestore, 'projects'), projectId), 'chatbots'), chatbotId), 'channels'),
    channelId,
  );

  rank += 1;
  await addDoc(collection(channelDocRef, 'chatLog'), {
    rank,
    message_type: 'text',
    text,
    created_at: new Date(),
  });
};

export const resetRank = () => {
  rank = 0;
};
