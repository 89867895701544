import '../styles/globals.scss';
import { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import { ChatProvider } from '../contexts/ChatContext';
import { GoogleAnalyticsAsyncScript } from '../components/atoms/GoogleAnalytics';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {typeof window !== undefined && (
        <RecoilRoot>
          <ChatProvider>
            <Component {...pageProps} />
          </ChatProvider>
        </RecoilRoot>
      )}
      {/* Needs render from server */}
      <GoogleAnalyticsAsyncScript />
    </>
  );
}

export default MyApp;
