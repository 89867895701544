import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { appState, messageState } from '../../../../states';

export const useHideChat = () => {
  const setApp = useSetRecoilState(appState);
  const setMessages = useSetRecoilState(messageState);
  const hideChat = useCallback(() => {
    setMessages([]);
    setApp((prev) => ({ ...prev, isChatOpen: false }));
  }, [setApp, setMessages]);
  return { hideChat };
};
