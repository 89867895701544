import { atom } from 'recoil';

interface IVariables {
  variables: Map<string, string>;
  currentSaveNameKey: string;
}

const initialState: IVariables = {
  variables: new Map(),
  currentSaveNameKey: '',
};

// HACK: 名前が微妙
export const messageVariablesState = atom({
  key: 'messageVariablesState',
  default: initialState,
});
