export type SignInArgs = {
  apiKey: string;
};

export type AuthIdentity = {
  firebaseToken: string;
  firebaseUuid: string;
  firebaseRefreshToken: string;
  firebaseEmail: string;
  firebaseTokenExpireAt: string;
};

export type Identity = Omit<AuthIdentity, 'user' | 'firebaseEmail'> & {
  firebaseEmail: null;
  expireAt: string;
  projectId: number;
  roomKey: string;
  roomName: string;
};

export type AnonymousAuthResponseBody = {
  identity: Identity;
  firestoreListenPath: string;
  realtimeDatabaseListenPath: string;
  waitingMovieId: number | null;
  waitingMoviePath: string | null;
  introductionMessage: string;
  firebaseCustomToken: string;
};

// TODO: add-backend 側で GraphQL 対応後に GraphQL 形式でリクエストするよう修正する
//  ref: https://github.com/avita-co-jp/add-customer-front/issues/157#issuecomment-1217491422
export type LegacyResponseBody = {
  data: {
    legacySignInConferenceAnonymously: AnonymousAuthResponseBody;
  };
  errors?: { message: string }[];
};

export async function signIn({ apiKey }: SignInArgs): Promise<AnonymousAuthResponseBody> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_ADD_BACKEND || 'http://localhost:8080'}/customer/anonymous/auth/sign_in/conference`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ apiKey }),
    },
  );

  if (!response.ok) {
    throw new Error('ログインに失敗しました');
  }

  const { data, errors } = (await response.json()) as LegacyResponseBody;

  if (errors && errors.length > 0) {
    // TODO: 適切なエラーメッセージ出力
    //   暫定的に一つ目のエラーメッセージを表示している
    throw new Error(errors[0].message);
  }

  return data.legacySignInConferenceAnonymously;
}
