import { getAuth, inMemoryPersistence, setPersistence, signInWithCustomToken } from 'firebase/auth';
import { firebaseApp } from './app';

const firebaseAuth = getAuth(firebaseApp);

export async function signInByCustomToken(customToken: string): Promise<void> {
  // 認証状態の永続性の変更
  // https://firebase.google.com/docs/auth/web/auth-state-persistence?hl=ja#modifying_the_auth_state_persistence
  await setPersistence(firebaseAuth, inMemoryPersistence);
  await signInWithCustomToken(firebaseAuth, customToken);
}
